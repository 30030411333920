import { render, staticRenderFns } from "./roll.vue?vue&type=template&id=4516b70a&scoped=true"
import script from "./roll.vue?vue&type=script&lang=js"
export * from "./roll.vue?vue&type=script&lang=js"
import style0 from "./roll.vue?vue&type=style&index=0&id=4516b70a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4516b70a",
  null
  
)

export default component.exports