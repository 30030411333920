<template>
    <div class="w-[60px] h-[60px] rounded-full box-show cursor-pointer" v-show="isScrollToTopVisible"  @click="scrollToTop">
        <img src="../assets/home/arrowTop-fill.png" alt="" class="w-[60px] h-[60px]">
    </div>
</template>
<script>
export default {
    name: 'Roll',
    data() {
        return {
            isScrollToTopVisible: false, // 控制按钮的显示和隐藏
            scrollThreshold: 300, // 滚动距离阈值，超过这个值显示按钮
        }
    },
    methods:{
        // 处理滚动事件
        handleScroll() {
        this.isScrollToTopVisible = window.scrollY > this.scrollThreshold;
        },        
        scrollToTop() {
            console.log(12312312312);
            
            // 平滑滚动（推荐）
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            // 或直接快速滚动
            // window.scrollTo(0, 0);
        },
         // 防抖函数
        debounce(func, wait) {
            console.log(123123);
            let timeout;
            return function (...args) {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), wait);
            };
        },
    },
    mounted() {
        // 监听滚动事件，并添加防抖
        window.addEventListener('scroll', this.debounce(this.handleScroll, 100));
    },
    beforeDestroy() {
        // 组件销毁时移除事件监听
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style lang="scss" scoped>
.box-show{box-shadow: 0px 0px 23px 0px #A7CAFF;}
</style>