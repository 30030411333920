export function initLazyBackground(selector = '.lazy-bg') {
    document.addEventListener('DOMContentLoaded', () => {
      // 获取所有需要懒加载背景图片的元素
      const lazyBackgrounds = document.querySelectorAll(selector);
  
      // 配置 Intersection Observer
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 当元素进入视口时，设置背景图片
            const bgUrl = entry.target.dataset.bg;
            entry.target.style.backgroundImage = `url(${bgUrl})`;
            observer.unobserve(entry.target); // 停止观察该元素
          }
        });
      }, {
        rootMargin: '0px', // 提前加载的边距
        threshold: 0.1, // 当元素 10% 进入视口时触发
      });
  
      // 开始观察所有懒加载元素
      lazyBackgrounds.forEach((bg) => observer.observe(bg));
    });
  }