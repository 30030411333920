import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'; // 引入 Tailwind CSS
import './assets/styles/global.css'; // 引入全局样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from './utils/request'
import { initLazyBackground } from '@/utils/imgLazy';

Vue.config.productionTip = false;

// 挂载到 Vue 实例的原型上
Vue.prototype.$initLazyBackground = initLazyBackground;
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$axios=axios;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
