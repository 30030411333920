<template>
    <div>
        <div class="w-[180px] h-[120px] bg relative cursor-pointer"  @click="toggleChat">
            <div class="absolute right-[20px] top-[60px] text-[#123980]">联系客服</div>
            <div v-if="newMsgCount > 0" class="w-[30px] h-[20px] rounded-full text-center leading-[20px] text-[#fff] bg-[#ff4d4f] absolute right-[0] top-8">{{ newMsgCount }}</div>
        </div>
         <!-- 聊天框主体 -->
    <div
      v-show="isChatOpen"
      class="fixed bottom-[20px] right-[70px] w-[500px] h-[500px] bg-white rounded-xl shadow-2xl flex flex-col"
    >
      <!-- 头部 -->
      <div class="chat-bg text-white px-5 py-4 rounded-t-xl flex justify-between items-center">
        <div class="flex items-center">
            <div class="w-[50px] h-[50px] mr-[30px] bg-[#fff] rounded-full flex justify-center items-center">
                <img src="../assets/logo/yulianshuju.png" class="w-[50px] h-[50px]">
            </div>
            <h2 class="text-lg font-semibold">羽链数据-官方客服</h2>
        </div>
        <span
          class="text-2xl cursor-pointer hover:scale-110 transition-transform duration-200"
          @click="toggleChat"
        >
          ×
        </span>
      </div>

      <!-- 聊天内容区域 -->
      <div class="flex-1 p-4 bg-gray-50 overflow-y-auto" ref="chatContent">
        <!-- 系统消息 -->
        <div class="text-center text-gray-600 text-sm my-4">欢迎咨询，我们将尽快为您服务</div>

        <!-- 客服消息 -->
        <div 
        class="max-w-[80%] bg-white text-gray-800 px-4 py-2 rounded-lg mb-[20px] shadow-sm border border-gray-200"
        style="border-radius: 12px 12px 12px 0;"
        ref="typingText"
        v-if="textStep"
      >
      hi，我们是一家专业从事互联网软件定制开发的服务商，有着多年的开发经验及各行业的开发案例；请您先描述一下项目需求，会有专门客服人员跟您详细解答。也可以直接拨打 18955151876 与我们联系。
      </div>
        <!-- 消息列表 -->
  <div class="flex flex-col gap-3" ref="chatRecordBox">
    <div 
      v-for="(item, index) in chatRecordList" 
      :key="index"
      :class="{'justify-end': item.role === 'customer'}"
      class="flex"
    >
      <!-- 客服消息 -->
      <div 
        v-if="item.role === 'service'"
        class=" bg-white text-gray-800 px-4 py-2 rounded-lg shadow-sm border border-gray-200"
        style="border-radius: 12px 12px 12px 0;"
      >
        {{ item.content }}
      </div>

      <!-- 用户消息 -->
      <div 
        v-if="item.role === 'customer'"
        class=" bg-[#236dff] text-white px-4 py-2 rounded-lg"
        style="border-radius: 12px 12px 0 12px;"
      >
        <span v-html="item.content"></span>
      </div>
    </div>
  </div>
      </div>
      <!-- 输入区域 -->
      <div class="border-t border-gray-200 p-4 flex gap-2">
        <input
          type="text"
          placeholder="输入消息..."
          v-model="inputText"
          @keydown="handleKeyDown"
          class="flex-1 px-4 py-2 border border-gray-300 rounded-full outline-none focus:ring-2 focus:ring-[#236dff]"
        />
        <el-button
          class="custom-btn"
          @click="sendMsg"
        >发送</el-button>
      </div>
    </div>
    </div>
</template>
<script>
import { OpenIMSDK,CbEvents } from 'open-im-sdk';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin'; // 引入 TextPlugin
// 注册 TextPlugin
gsap.registerPlugin(TextPlugin);
export default {
    name: 'Lxkf',
    data() {
        return {
            isChatOpen: false,
            textStep: false,
            deposit:false,
            OpenIM: '',
            inputText:'',
            newMsgCount:0,
            chatRecordList:[
            //   {
            //     role:'service',
            //     content: 'hi，我们是一家专业从事互联网软件定制开发的服务商，有着多年的开发经验及各行业的开发案例；请您先描述一下项目需求，会有专门客服人员跟您详细解答。也可以直接拨打 18955151876 与我们联系。' // 这是消息的内容
            // }
            ],
            fullText: `本公司专注于个性化软件定制开发服务；对APP、 微信小程序、PC网站、物联网、智慧大屏等互联网系统有着丰富的开发经验， 能够为您提供最具性价比的开发方案和最真挚的服务。公司对代码质量高度重视，对数据价值深度挖掘。`,
        }
    },
   async created(){
        this.OpenIM = new OpenIMSDK();
        this.OpenIM.on(CbEvents.OnConnecting, ()=>{
            console.log('OnConnecting')
        });
        this.OpenIM.on(CbEvents.OnConnectFailed,  () => {
            console.log('OnConnectFailed')
        });
        this.OpenIM.on(CbEvents.OnConnectSuccess, ()=>{
            console.log('OnConnectSuccess')
        });
        this.OpenIM.on(CbEvents.OnUserTokenExpired, () => {
            console.log('onUserTokenExpired')
        });
        this.OpenIM.on(CbEvents.OnKickedOffline, () => {
            console.log('OnKickedOffline')
        });
        this.OpenIM.on(CbEvents.OnRecvNewMessages, (e)=>{
        console.log('OnRecvNewMessages')
        console.log(e)
        if(e.data[0].contentType === 101){
          this.chatRecordList.push({
            role: "service",
            content: e.data[0].textElem.content,
            type: "text",
          })
          if(!this.isChatOpen){
            this.newMsgCount++
          }
          this.scrollToBottom()
        }
      });
      await this.setCookie(); // 在组件挂载时调用 setCookie 方法
    },
    watch: {
      // 监听 chatRecordList 的变化，自动滚动到底部
      chatRecordList() {
        this.scrollToBottom();
      },
    },
    mounted(){
    },
    methods: {
      initTypingAnimation() {
        const element = this.$refs.typingText;

        // 清空初始文本
        element.textContent = '';

        // 使用 TextPlugin 逐步显示文本
        gsap.to(element, {
          duration: this.fullText.length * 0.05, // 总时长（根据文本长度调整）
          text: {
            value: this.fullText, // 最终显示的完整文本
            delimiter: '', // 按字符分割
          },
          ease: 'none', // 线性动画（模拟打字机效果）
          onComplete: () => {
              // 打字机效果展示完毕后执行的操作
              this.chatRecordList.push({
                  role: "service",
                  content: '您好，请先描述一下您的需求，客服会第一时间进行解答。',
                  type: "text",
              })
          },
        });
        
      },
        toggleChat() {
            this.isChatOpen = !this.isChatOpen
            if(this.isChatOpen){
              this.newMsgCount = 0
            }
            this.textStep = true
            if(!this.deposit){
              this.$nextTick(()=>{
                this.deposit = true
                this.initTypingAnimation();
              })
            }
        },
        setCookie() {
            this.$axios({
                method: 'post', 
                url: 'https://yulianshuju.com/api/visitor.visitor/login', 
                withCredentials: true, // 跨域请求时是否携带凭证
                data: {},
                timeout:20000,
            })
            .then((response) => {
                console.log(response.data,'cookie');
                // IM登录
                const userID = response.data.data.visitor.id.toString()
                const token = response.data.data.token.token
                this.OpenIM.login({
                    userID: userID,// IM 用户 userID
                    token: token,// IM 用户令牌
                    platformID: 5,// 当前登录平台号，web端为5
                    wsAddr: 'wss://openim.ruanjian.art/msg_web_gateway',
                    apiAddr: 'https://openim.ruanjian.art/msg_gateway',
                }).then((loginRes) => {
                    console.log(loginRes, 'loginRes');
                }).catch(({ errCode, errMsg }) => {
                    console.log(errMsg, 'errMsg');
                    this.$message({
                    message: '当前网络环境不稳定，请稍后再试！',
                    type: "error",
                    })
                });
            })
            .catch((error) => {
                console.error('Request failed:', error);
                this.text = 'request failed';  
            });
        },
        async sendMsg(){
            // let content = this.$refs.visitorSendContentBox_Ref.innerHTML;
            if (!this.inputText) {
                this.$message({
                message: "请输入发送内容！",
                type: "warning",
                });
                return;
            }
            const createTextMessage = await this.OpenIM.createTextMessage(this.inputText)
            console.log('createTextMessage',createTextMessage)
            this.OpenIM.sendMessage({
            // recvID: "8820608524",
            recvID: "6130304622",
            groupID: "",
            message: createTextMessage['data']
            })
            this.chatRecordList.push({
                role: "customer",
                content: this.inputText,
                type: "text",
            })
            console.log(this.chatRecordList,'this.chatRecordList');
            // this.$refs.visitorSendContentBox_Ref.innerHTML = "";
            this.inputText = ''
            // this.scrollToBottom()
        },
        handleKeyDown(event) {
          if (event.key === 'Enter') {
            event.preventDefault();  // 阻止默认行为（避免插入换行符）
            // 在这里可以执行你想要的操作，例如发送消息
            this.sendMsg();
            // 你可以在此清空内容或处理内容
          }
        },
        // 滚动到底部
        scrollToBottom() {
          this.$nextTick(() => {
            const chatContent = this.$refs.chatContent;
            if (chatContent) {
              chatContent.scrollTo({
                top: chatContent.scrollHeight, // 滚动到最底部
                behavior: 'smooth', // 平滑滚动
              });
            }
          });
        },
        
    }
}
</script>
<style lang="scss" scoped>
.bg{
    background-image: url('../assets/home/lxkf.png');
    background-size: 100% 100%;    
}
::v-deep .custom-btn{
  height: 38px;
  border: none;
  background:#236dff;
  color: #fff;
}
.custom-btn:hover{
  background: #236dff;
  color: #fff;
}
.chat-bg{
  background: url(../assets/logo/chat-bg.png) no-repeat 90% 70%;
}
</style>