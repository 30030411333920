  <template>
    <div class="bg-[#f2f1f1] h-[350px] py-4">
      <div class="pl-[80px]">
        <p class="text-[#666] my-[20px]">首页</p>
        <p class="h-[1px] bg-[#ccc] mb-[30px]"></p>
        <div class="flex relative">
            <div>
                <div class="flex justify-between w-[600px]">
                  <div class="text-[#333333] text-[20px] cursor-pointer" @click="about">关于羽链数据</div>
                  <div class="text-[#333333] text-[20px] cursor-pointer" @click="cooperation">合作案例</div>
                  <div class="text-[#333333] text-[20px] cursor-pointer" @click="profession">业务领域</div>
              </div>
              <div class="text-[#666666]">
                <div class="my-[20px] flex items-center"> <div class="mx-[5px]"> <img src="../assets/footer/dizhi.png" class="w-[18px] h-[18px]" alt=""> </div> 公司地址：安徽省合肥市蜀山区南七街道黄山路451号 合肥世界之窗创新产业园A座1203室</div>
                <div class=" flex items-center"> <div class="mx-[5px]"> <img src="../assets/footer/lianxidianhua.png" class="w-[23px] h-[24px]" alt=""> </div> 联系电话：18955151876</div>
              </div>
            </div>
            <!-- right -->
            <div class="ml-[100px] relative">
                <img src="../assets/footer/QRCODE.png" class="w-[550px] h-[120px]" alt="">
                <div class="flex justify-between">
                  <div class="absolute left-[6%] text-[#666666] mt-[10px]">企业微信</div>
                  <div class="absolute left-[34%] text-[#666666] mt-[10px]">抖音</div>
                  <div class="absolute left-[60%] text-[#666666] mt-[10px]">快手</div>
                  <div class="absolute left-[86%] text-[#666666] mt-[10px]">小红书</div>
                </div>
            </div>
            <!--  -->
            <div class="absolute w-[430px] right-[0]">
              <img src="../assets/home/gsjjBG.png" class="w-[100%]" alt="">
            </div>
        </div>
        <div class="text-[#C0BDBD] flex justify-center mt-[60px]">备案号:皖ICP备2024060110号-1</div>
      </div>
    </div>
</template>
<script>
export default{
name :'FooterView',
methods:{
  about(){
    this.$router.push('/about')
  },
  cooperation(){
    this.$router.push('/cooperation')
  },
  profession(){
    this.$router.push('/profession')
  },
}
}
</script>