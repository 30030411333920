<template>
    <div>
        <div class="top">
          <div class="GIF z-[-999]"></div>
            <div class="absolute top-0 right-0 py-[140px] w-[100%] box-border z-[999]">
              <div class="flex justify-center items-center" ref="slideElement">
            <img class="w-[600px] h-[125px] mt-[50px] lazy-bg" src="../../assets/home/homeText.png" alt="">
        </div>
        <!-- 导航提示 -->
        <div  class="flex justify-center items-center mt-[75px] font-[优设标题]" ref="navElement">
            <div v-for="item in list" :key="item.id">
                <div class="text-[#fff] text-[23px] mx-[20px] flex items-center"> 
                    <p class="w-[10px] h-[10px] mr-[10px] bg-[#fff] rounded-full"></p> 
                    {{ item.text }}
                </div>
            </div>
        </div>
        <!-- 报价 -->
        <div class="flex items-center ml-[50%] box-border pl-[10px] translate-x-[-50%] mt-[80px] bg-[#fff] w-[700px] rounded-sm">
            <div class="w-[210px] flex justify-center items-center">
                <el-dropdown trigger="click" placement="bottom">
                    <span class="el-dropdown-link text-[#999]">
                      {{ currentItem }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <div v-for="item in design" 
                                  :key="item.id"
                                  @click="select(item)">
                          <el-dropdown-item>{{ item.name }}</el-dropdown-item>
                        </div>
                      </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div class="line"></div>
            <input class="input-number text-[#999]  text-center"  v-model="mobile" placeholder="请输入手机号">
            <div class="line"></div>
            <input class="input-number  text-[#999] text-center" v-model="price" placeholder="请输入预算">
            <button class="text-[#fff] rounded-r-[2px] w-[160px] h-[40px]" style="background: linear-gradient( 90deg, #154CFC 0%, #79D9E9 100%);" @click="submitForm">查看报价</button>
        </div>
        <div class="text-[#fff] w-[700px] ml-[50%] mt-[30px] translate-x-[-50%] bgimg">已有 
            <!-- <span class="text-[#ecb521] text-[18px]">826</span> -->
            <countTo class="text-[#ecb521] text-[18px]" :startVal='startVal' :endVal='endVal826' :duration='2000'></countTo>
             人正在获取报价</div>
        <!-- xxxx -->
        <div class="w-[1700px] h-[230px] bg-[#fff] pl-[50px] ml-[50%] mt-[130px] translate-x-[-50%] rounded-sm flex bg-show">
            <div class="relative box-border">
              <div class="flex justify-between w-[100px] absolute top-[50px] right-[60px] mr-[10px]">
                <div class="w-[60px] h-[4px] rounded-md bg-[#1559FB]"></div>
                <div class="w-[35px] h-[4px] rounded-md bg-[#40E2FD]"></div>
              </div>
              <div class="w-[400px] h-[100%] flex items-center">
                <div>
                    <img src="../../assets/home/1.png" class="lazy-bg w-[215px] h-[118px] rotating-image" alt="">
                </div>
                <div>
                    <p  class="text-[#333333] text-[22px]">源码交付</p>
                    <p  class="text-[#333333] text-[22px]">售后无忧</p>
                </div>
              <div class="w-[2px] h-[80px] bg-[#F3F3F3] absolute right-2"></div>
              </div>
              <div class=" w-[50px] flex justify-around absolute bottom-[10px]">
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#ddf5f7] rounded-full"></div>
              </div>
            </div>
            <div class="relative box-border">
              <div class="flex justify-between w-[100px] absolute top-[50px] right-[60px] mr-[10px]">
                <div class="w-[60px] h-[4px] rounded-md bg-[#1559FB]"></div>
                <div class="w-[35px] h-[4px] rounded-md bg-[#40E2FD]"></div>
              </div>
              <div class="w-[400px] h-[100%] flex items-center">
                <div>
                    <img src="../../assets/home/2.png" class=" lazy-bg w-[215px] h-[118px] rotating-image" alt="">
                </div>
                <div>
                    <p  class="text-[#333333] text-[22px]">用户为本</p>
                    <p  class="text-[#333333] text-[22px]">服务之上</p>
                </div>
                <div class="w-[2px] h-[80px] bg-[#F3F3F3] absolute right-2"></div>
              </div>
              <div class=" w-[50px] flex justify-around absolute bottom-[10px]">
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#ddf5f7] rounded-full"></div>
              </div>
            </div>
            <div class="relative box-border">
              <div class="flex justify-between w-[100px] absolute top-[50px] right-[60px] mr-[10px]">
                <div class="w-[60px] h-[4px] rounded-md bg-[#1559FB]"></div>
                <div class="w-[35px] h-[4px] rounded-md bg-[#40E2FD]"></div>
              </div>
              <div class="w-[400px] h-[100%] flex items-center">
                <div>
                    <img src="../../assets/home/3.png" class=" lazy-bg w-[215px] h-[118px] rotating-image" alt="">
                </div>
                <div>
                    <p  class="text-[#333333] text-[22px]">价格透明</p>
                    <p  class="text-[#333333] text-[22px]">合同保障</p>
                </div>
                <div class="w-[2px] h-[80px] bg-[#F3F3F3] absolute right-2"></div>
              </div>
              <div class=" w-[50px] flex justify-around absolute bottom-[10px]">
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#ddf5f7] rounded-full"></div>
              </div>
            </div>
            <div class="relative box-border">
              <div class="flex justify-between w-[100px] absolute top-[50px] right-[60px]">
                <div class="w-[60px] h-[4px] rounded-md bg-[#1559FB]"></div>
                <div class="w-[35px] h-[4px] rounded-md bg-[#40E2FD]"></div>
              </div>
              <div class="w-[400px] h-[100%] flex items-center">
                <div>
                    <img src="../../assets/home/4.png" class=" lazy-bg w-[215px] h-[118px] rotating-image" alt="">
                </div>
                <div>
                    <p  class="text-[#333333] text-[22px]">创新为本</p>
                    <p  class="text-[#333333] text-[22px]">承诺是金</p>
                </div>
              </div>
              <div class=" w-[50px] flex justify-around absolute bottom-[10px]">
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#4EAAF6] rounded-full"></div>
                 <div class="w-[8px] h-[8px] bg-[#ddf5f7] rounded-full"></div>
              </div>
            </div>
        </div>
            </div>
        </div>
         <!-- ccccc -->
         <div class="h-[500px] mt-[30px] box-border relative">
            <div class="w-[1200px] h-[450px] px-[20px] ml-[50%] translate-x-[-50%] mt-[80px]">
                    <div ref="jieshaoElement" class="flex items-center justify-center">
                        <img src="../../assets/home/left.png" class="w-[46px] h-[28px]" alt="">
                        <div class="text-[#333333] text-[28px] mx-[15px]">公司介绍</div>
                        <img src="../../assets/home/right.png" class="w-[46px] h-[28px]" alt="">
                    </div>
                    <div class="my-[30px] flex">
                        <div class="mr-[35px]">
                            <div class="w-[400px] bg-[#d9d9d9] p-[8px] rounded-lg">
                                <video src="../../assets/home/promotionalVideo.mp4" controls
                                    autoplay
                                    loop
                                    muted type="video/mp4" class="w-full h-auto"></video>
                            </div>
                        </div>
                        <div class="">
                            <div class="w-[600px] px-[20px] h-[300px]">
                                <div class="text-[#333333] text-[28px]">羽链数据，代码“质”造</div>
                                <div class="text-[#666666] text-[13px] leading-[21.09px] mt-[30px] indent-4">
                                  2015年成立于安徽省合肥市，公司专注于软件定制九年，对APP， 微信小程序，PC网站，物联网，智慧大屏等互联网系统有着丰富的开发经验， 能够为客户提供最具性价比的开发方案和最真挚的服务。公司对代码质量高度重视，对数据价值深度挖掘，以专业铸就成长，为合作企业保驾护航。
                                </div>
                                <div ref="targetElement" class="flex items-center mt-[60px] justify-between text-center">
                                    <div>
                                        <countTo class="text-[#3B80F4] text-[35px]" v-if="isVisible" :startVal='startVal' :endVal='endVal' :duration='2000'></countTo>
                                        <span class="text-[#3B80F4] ml-[-10px]">+</span>
                                        <div class="text-[#666666] text-[12px]">丰富的行业经验及技术沉淀</div>
                                    </div>
                                    <div>
                                        <countTo class="text-[#3B80F4] text-[35px]" v-if="isVisible" :startVal='startVal' :endVal='endVal2' :duration='2000'></countTo>
                                        <span class="text-[#3B80F4] ml-[-10px]">+</span>
                                        <div class="text-[#666666] text-[12px]">服务范围辐射34个省市和地区</div>
                                    </div>
                                    <div>
                                        <countTo class="text-[#3B80F4] text-[35px]" v-if="isVisible" :startVal='startVal' :endVal='endVal3' :duration='2000'></countTo>
                                        <span class="text-[#3B80F4] ml-[-10px]">+</span>
                                        <div class="text-[#666666] text-[12px]">客户涵盖大中心型企业、高校、政府等</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
            </div>
            <div class="absolute bottom-0 right-0">
                <img src="../../assets/home/gsjjBG.png" class=" lazy-bg w-[600px] h-[300px]" alt="">
            </div>
        </div>
        <!-- 我们核心优势 -->
        <div class="h-[500px] box-border youshi pt-[30px]">
            <div ref="youshiElement" class="flex items-center justify-center">
                <img src="../../assets/home/left.png" class="w-[46px] h-[28px]" alt="">
                <div class="text-[#333333] text-[28px] mx-[15px]">我们核心优势</div>
                <img src="../../assets/home/right.png" class="w-[46px] h-[28px]" alt="">
            </div>
            <div class="w-[80%] flex justify-between h-[400px] ml-[50%] translate-x-[-50%] pt-[30px]">
                <div class="youshi1 w-[300px] h-[350px] relative">
                    <div class="absolute top-[105px] ml-[50%] translate-x-[-50%]">独特性与个性化</div>
                    <p class="absolute top-[155px] text-[14px] px-[10px] py-[25px] text-[#FFFFFF]">定制性强:软件开发定制平台能够根据企业的具体需求和业务流程量身定制软件解决方案， 从而精准满足企业的独特需求， 这种个性化定制的优势使得软件能够更好地适应企业的实际情况，提升工作效率和业务运营水平。</p>
                </div>
                <div class="youshi2 w-[300px] h-[350px] relative">
                    <div class="absolute top-[105px] ml-[48%] translate-x-[-48%]">技术选型和架构先进</div>
                    <p class="absolute top-[155px] text-[14px] px-[10px] py-[25px] text-[#FFFFFF]">主制开发的软件通常采用最新的技术和架构，这使得软具有更强的可扩展性和灵活性，便于后期的维护和升级还能确保软件能够跟上技术这不仅有助于提升软件的性能，加快发展的步伐。</p>
                </div>
                <div class="youshi3 w-[300px] h-[350px] relative">
                    <div class="absolute top-[105px] ml-[50%] translate-x-[-50%]">费用效益</div>
                    <p class="absolute top-[155px] text-[14px] px-[10px] py-[25px] text-[#FFFFFF]">尽管软件定制开发的初始成本可能较高但考虑到长期来看，能够提升效率、降低资源浪费，从而实现更好的费用效益。此外，定制开发的软件可以根据企业的发展进行功能扩展和升级，确保软件始终适应企业的发展方向。</p>
                </div>
                <div class="youshi4 w-[300px] h-[350px] relative">
                    <div class="absolute top-[105px] ml-[48%] translate-x-[-48%]">数据安全与隐私保护</div>
                    <p class="absolute top-[155px] text-[14px] px-[10px] py-[25px] text-[#FFFFFF]">定制软件专为特定企业环境设计，减少了数据泄露的风险，提高了数据安全性，同时，可以根据企业的安全需才进行定制化设计，增强软件的安全防护措施，确保敏感数据得到更好的保护。</p>
                </div>
            </div>
        </div>
        <!-- 业务领域 -->
        <div class="h-[500px] box-border pt-[30px]">
            <div ref="yewuElement" class="flex items-center justify-center">
                <img src="../../assets/home/left.png" class="w-[46px] h-[28px]" alt="">
                <div class="text-[#333333] text-[28px] mx-[15px]">业务领域</div>
                <img src="../../assets/home/right.png" class="w-[46px] h-[28px]" alt="">
            </div>
            <div class="w-[80%] flex justify-between h-[370px] ml-[50%] translate-x-[-50%]">
                <div class="w-[620px] mt-[30px] h-full relative">
                    <div class="absolute left-0">
                        <img src="../../assets/home/yewuLOGO1.png" class="w-[271px] h-[170px]" alt="">
                    </div>
                    <div class="absolute right-0">
                        <img src="../../assets/home/yewuLOGO2.png" class="w-[271px] h-[170px]" alt="">
                    </div>
                    <div class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-10">
                        <img src="../../assets/home/logo1.webp" class="w-[120px] h-[125px]" alt="">
                    </div>
                    <div class="absolute bottom-0">
                        <img src="../../assets/home/yewuLOGO3.png" class="w-[271px] h-[170px]" alt="">
                    </div>
                    <div class="absolute bottom-0 right-0">
                        <img src="../../assets/home/yewuLOGO4.png" class="w-[271px] h-[170px]" alt="">
                    </div>
                </div>

                <div class="w-[730px]">
                    <p class="text-[#666666] text-[24px] flex justify-center">全行业发展服务</p>
                    <img src="../../assets/home/quanhangyefazhan.png" class="w-[600px] h-[333px] mt-[30px] ml-[50%] translate-x-[-50%]" alt="">
                </div>
            </div>
        </div>
        <!-- 合作案例 -->
        <div class="box-border pt-[30px]">
            <div ref="hezuoElement" class="flex items-center justify-center mb-[30px]">
                <img src="../../assets/home/left.png" class="w-[46px] h-[28px]" alt="">
                <div class="text-[#333333] text-[28px] mx-[15px]">合作案例</div>
                <img src="../../assets/home/right.png" class="w-[46px] h-[28px]" alt="">
            </div>
            <!-- Tabs -->
                <div class="w-[80%] flex justify-start ml-[50%] translate-x-[-50%]">
                    <div class="flex space-x-4 relative">
                    <button
                        v-for="(tab) in tabsList"
                        :key="tab.id"
                        @click="setActiveTab(tab.id)"
                        :class="[
                        'px-4 py-2 text-sm font-medium focus:outline-none relative',
                        activeTab === tab.id
                            ? 'text-blue-600'
                            : 'text-gray-500 hover:text-gray-700',
                        ]"
                    >
                            {{ tab.name }}
                        <!-- 底部横线 -->
                            <span
                            v-if="activeTab === tab.id"
                            class="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform origin-center transition-all duration-300"
                            :class="{ 'scale-x-100': activeTab === tab.id }"
                            ></span>
                        </button>
                    </div>
                </div>
                <div class=" w-[80%] mt-[10px] ml-[50%] translate-x-[-50%] text-[#666666] text-[15px] cursor-pointer text-right" @click="cooperation">查看更多  ></div>
            <!-- Tab Content -->
            <div class="flex w-[80%] mt-[50px] ml-[50%] translate-x-[-50%]">
                <div  class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 items-stretch gap-4 w-[95vw]">
                    <div v-for="item in tabsContent" :key="item.id">
                        <div class="case-content-row h-[330px] px-2 mb-[15px] p-[8px] overflow-hidden rounded-lg bg-[#fff]" style="box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.09);">
                            <div class=" h-[190px] rounded-md overflow-hidden">
                              <img :src="item.image" class="object-scale-down lazy-bg  rounded-md w-[100%] h-[100%] hoveimgZoom">
                            </div>
                            <div class="flex items-center">
                                <div class="w-[4px] h-[20px] mr-[8px] rounded-sm" style="background: linear-gradient( 180deg, #1F92DF 0%, #31BDF2 100%);"></div>
                                <div class="text-[#333333] text-[20px] my-[10px]">{{ item.name }}</div>
                            </div>
                            <div class="text-[#666] text-[14px] mt-[10px]">{{ item.description }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 合作单位 -->
        <div>
            <div class="flex items-center justify-center my-[45px]">
                <img src="../../assets/home/left.png" class="lazy-bg w-[46px] h-[28px]" alt="">
                <div class="text-[#333333] text-[28px] mx-[15px]">合作单位</div>
                <img src="../../assets/home/right.png" class="lazy-bg w-[46px] h-[28px]" alt="">
            </div>
          <div class="box-border h-[238px] flex justify-around items-center hezuodanwei">
            
            <!-- 走马灯 -->
            <div class="w-[80%] mx-auto overflow-hidden">
                <div class="flex justify-around items-center w-[200%] h-[100px] marquee">
                    <div>
                    <img src="../../assets/home/strong1.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong2.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong3.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong4.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong5.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <!-- 重复一遍图片，实现无缝滚动 -->
                    <div>
                    <img src="../../assets/home/strong1.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong2.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong3.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong4.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                    <div>
                    <img src="../../assets/home/strong5.png" class="lazy-bg w-[200px] h-[60px]" alt="">
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import { gsap } from 'gsap';
import CountTo from 'vue-count-to';
export default {
    name: 'Home',
    components: {
        CountTo,
    },
    data() {
        return {
            list:[
                {text:'网站',id:1},
                {text:'APP',id:2},
                {text:'小程序',id:3},
                {text:'物联网',id:4},
                {text:'可视化大屏',id:5},
            ],
            design:[],
            value: '',
            startVal: 0,
            endVal: 10,
            endVal2: 30,
            endVal3: 2000,
            endVal826:null,
            isVisible: false, // 是否进入可视区域
            tabsList:[],
            activeTab: 0, // 默认激活第一个 tab
            tabsContent:[],
            currentItem:"请选择",
            type:'',//开发类型
            mobile:'',
            price:'',//开发预算
            name: '',
        }
    },
    created(){
        this.getSelectData()
        this.getTabsTitle()
        this.getSubmitCount()
    },
    methods:{
      getSubmitCount(){
        this.$axios.get('customer.customerRequirement/allCount').then(res=>{
            console.log('res',res);
            this.endVal826 = res.data.data.number
        })
      },
      cooperation(){
        this.$router.push('/cooperation')
      },
      // 查看报价
      submitForm(){
        if(this.mobile === '') {
          this.$message.error("手机号不能为空");
          return
        }
        this.$axios.post('/customer.customerRequirement/add', {
        name: this.name,
        type: this.type,
        price: this.price,
        mobile: this.mobile
      }).then((res) => {
        if (res.data.code === 1) {
          this.$message.success('已经了解你的需求，稍后会有专业的人联系您！！！！', '报价', {
            confirmButtonText: 'OK',
          });
          this.mobile='';
          this.price='',
          this.currentItem = '请选择'
          this.getSubmitCount()
        } else {
          this.$message.error(res.data.msg);
        }
        console.log(res);
      }).catch((error) => {
        console.error("请求失败", error);
      });
      },
      select(item){
        this.type = item.value
        // 更新显示的当前项
        this.currentItem = item.name;
    },
        setActiveTab(index) {
            this.activeTab = index;
            this.lbid = index;
            this.getTabsContent()
        },
        getTabsTitle(){
            this.$axios.get('/case.caseType/getAll').then(res=>{
                this.tabsList = res.data.data;
                this.setActiveTab(0)
            })
        },
        getTabsContent(){
            this.$axios.get('/case.cases/getCases?case_type_id=' + this.lbid).then(res=>{
                console.log(res,'getTabsContent');
                this.tabsContent = res.data.data;
                // .slice(0, 4)
                if(this.tabsContent.length>4){
                  this.tabsContent = this.tabsContent.slice(0, 4)
                }
            })
        },
        getSelectData () {
            this.$axios.get('/index/dict',{
                params : {
                type: 'development_type'
                }
            }).then((res)=>{
                this.design=res.data.data.development_type
                console.log(res.data)
            })
        },
         // 初始化 Intersection Observer 公司介绍
    initIntersectionObserver() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 元素进入可视区时触发动画
              this.playScaleAnimation();
              // 停止观察，避免重复触发
              observer.unobserve(entry.target);
            }
          });
        },
        {
          root: null, // 相对于视口
          threshold: 0.5, // 当元素 50% 进入视口时触发
        }
      );

      // 开始观察目标元素
      observer.observe(this.$refs.jieshaoElement);
    },
    // 播放缩放动画  公司介绍
    playScaleAnimation() {
      gsap.fromTo(
        this.$refs.jieshaoElement, // 动画元素
        {
          scale: 0, // 初始状态：缩小到 0
          opacity: 0, // 初始状态：完全透明
        },
        {
          scale: 1, // 目标状态：恢复到原始大小
          opacity: 1, // 目标状态：完全不透明
          duration: 1, // 动画时长：1 秒
          ease: 'power2.out', // 缓动效果
        }
      );
    },
    // 我们的核心优势
    initIntersectionObserverYoushi() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 元素进入可视区时触发动画
              this.playScaleAnimationYoushi();
              // 停止观察，避免重复触发
              observer.unobserve(entry.target);
            }
          });
        },
        {
          root: null, // 相对于视口
          threshold: 0.5, // 当元素 50% 进入视口时触发
        }
      );

      // 开始观察目标元素
      observer.observe(this.$refs.youshiElement);
    },
     // 播放缩放动画 我们的核心优势
     playScaleAnimationYoushi() {
      gsap.fromTo(
        this.$refs.youshiElement, // 动画元素
        {
          scale: 0, // 初始状态：缩小到 0
          opacity: 0, // 初始状态：完全透明
        },
        {
          scale: 1, // 目标状态：恢复到原始大小
          opacity: 1, // 目标状态：完全不透明
          duration: 1, // 动画时长：1 秒
          ease: 'power2.out', // 缓动效果
        }
      );
    },
     // 业务领域
     initIntersectionObserverYewu() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 元素进入可视区时触发动画
              this.playScaleAnimationYewu();
              // 停止观察，避免重复触发
              observer.unobserve(entry.target);
            }
          });
        },
        {
          root: null, // 相对于视口
          threshold: 0.5, // 当元素 50% 进入视口时触发
        }
      );

      // 开始观察目标元素
      observer.observe(this.$refs.yewuElement);
    },
     // 播放缩放动画 我们的核心优势
     playScaleAnimationYewu() {
      gsap.fromTo(
        this.$refs.yewuElement, // 动画元素
        {
          scale: 0, // 初始状态：缩小到 0
          opacity: 0, // 初始状态：完全透明
        },
        {
          scale: 1, // 目标状态：恢复到原始大小
          opacity: 1, // 目标状态：完全不透明
          duration: 1, // 动画时长：1 秒
          ease: 'power2.out', // 缓动效果
        }
      );
    },
     // 合作案例
     initIntersectionObserverHezuo() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 元素进入可视区时触发动画
              this.playScaleAnimationHezuo();
              // 停止观察，避免重复触发
              observer.unobserve(entry.target);
            }
          });
        },
        {
          root: null, // 相对于视口
          threshold: 0.5, // 当元素 50% 进入视口时触发
        }
      );

      // 开始观察目标元素
      observer.observe(this.$refs.hezuoElement);
    },
     // 播放缩放动画 我们的核心优势
     playScaleAnimationHezuo() {
      gsap.fromTo(
        this.$refs.hezuoElement, // 动画元素
        {
          scale: 0, // 初始状态：缩小到 0
          opacity: 0, // 初始状态：完全透明
        },
        {
          scale: 1, // 目标状态：恢复到原始大小
          opacity: 1, // 目标状态：完全不透明
          duration: 1, // 动画时长：1 秒
          ease: 'power2.out', // 缓动效果
        }
      );
    },
    },
    mounted() {
        // 初始化 Intersection Observer
        this.initIntersectionObserver();
        this.initIntersectionObserverYoushi();
        this.initIntersectionObserverYewu();
        this.initIntersectionObserverHezuo();
        // 使用 GSAP 实现动画
        gsap.from(this.$refs.slideElement, {
        x: '-100%', // 从屏幕左边开始
        duration: 1.5, // 动画时长
        ease: 'power2.out', // 缓动效果
        });
        gsap.from(this.$refs.navElement, {
        x: '100%', // 从屏幕右边开始
        duration: 1.5, // 动画时长
        ease: 'power2.out', // 缓动效果
        });
         // 创建 Intersection Observer 实例
         const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    console.log('Element is intersecting:', entry.isIntersecting); // 打印日志
                    this.isVisible = true;
                    observer.unobserve(entry.target); // 停止观察
                }
                })},
                { threshold: 0.5}, // 当元素 10% 进入可视区域时触发}
        );

            // 开始观察目标元素
            observer.observe(this.$refs.targetElement);
            // 调用懒加载图片方法
            this.$initLazyBackground();
          },
      }
</script>
<style  lang='scss' scoped>
/* 走马灯动画 */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.marquee:hover {
  animation-play-state: paused;
}
.marquee {
  animation: marquee 15s linear infinite; /* 10s 完成一次滚动，无限循环 */
}

.hezuodanwei{
    background-image: url('../../assets/home/hezuodanwei.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.hoveimgZoom{
    object-fit: cover; /* 图片按比例缩放并覆盖容器 */
    transition: transform 1s ease; /* 添加过渡效果 */
}
.hoveimgZoom:hover {
    transform: scale(1.1);
}
/* 内容切换动画 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* 底部横线动画 */
.scale-x-100 {
  transform: scaleX(1);
}

button span {
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.youshi{
    background-image: url('../../assets/home/youshi.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.youshi1{
    background-image: url('../../assets/home/youshi1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.youshi2{
    background-image: url('../../assets/home/youshi2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.youshi3{
    background-image: url('../../assets/home/youshi3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.youshi4{
    background-image: url('../../assets/home/youshi4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.input-number{
  width: 210px;
  height: 40px;
  border: none;
  outline: none;
  font-size: 17px;
}
  /* 利用穿透，设置input边框隐藏 */
  ::v-deep .el-input__inner {
    border-radius: 0 !important;
  }

::v-deep.el-input .el-input__wrapper.is-focus{
  box-shadow: none!important;
}
::v-deep .el-select .el-input.is-focus .el-input__wrapper {
  border-color: #fff  !important;
  box-shadow: none!important;
}
.line{
  height: 20px;
  margin:0px 15px;
  border-left: 1px solid #ccc;
}
input::placeholder {
text-align:center;
color: #999; /* 文字颜色 */
font-size: 14px; /* 文字大小 */
}
.bgimg{
    background-image: url('../../assets/home/numberBG.png');
    background-size: 35% 100%;
    background-repeat: no-repeat;
}
.bg-show{
    box-shadow: 0 12px 12px -12px rgba(0, 0, 0, 0.3);
}
.container {
      perspective: 1000px; /* 透视效果 */
      margin: 50px;
    }

    .rotating-image {
      width: 200px;
      height: auto;
      animation: rotateY 2s linear 1; /* 动画只播放一次 */
      transform-style: preserve-3d; /* 确保 3D 变换 */
    }

    /* 定义旋转动画 */
    @keyframes rotateY {
      from {
        transform: rotateY(0deg);
      }
      to {
        transform: rotateY(360deg);
      }
    }
    .top{
        width: 100%;
        height: 954px;
        background-image: url("../../assets/home/backgroundIMG.webp");
        // background-image: url("https://yulianshuju1.oss-cn-hangzhou.aliyuncs.com/backgroundIMG.webp");
        background-repeat: no-repeat;
        background-size: 100% 90%;
        z-index: 999;
        box-sizing: border-box;
    }
    .GIF{
      width: 100%;
      height: 954px;
      background-repeat: no-repeat;
      // background-image: url("https://yulianshuju1.oss-cn-hangzhou.aliyuncs.com/top-di.gif");
      background-image: url("../../assets/home/top-di.gif");
      background-size: 100% 90%;
      mix-blend-mode: multiply; 
      opacity: 0.15;
      z-index: 999;
    
    }
    /* 未选择内容时的文字颜色 */
.el-dropdown-menu__item {
  color: #606266; /* 设置未选择时的文字颜色 */
}

/* 选择之后的内容字体颜色 */
.el-dropdown-menu__item.is-active {
  color: #409EFF; /* 设置选中后的文字颜色 */
}

/* 自定义选中状态的样式 */
.active-item {
  font-weight: bold; /* 可选：加粗字体 */
}
</style>