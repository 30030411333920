<template>
  <div id="app">
       <!-- 头部导航条 -->
       <div class=" pt-[50px] absolute z-[9999999999999]">
          <Header />
       </div>
       <router-view/>
       <!-- 联系客服悬浮窗 -->
       <div class="fixed top-[200px] right-[3px] z-[99999999999999999999]">
        <Lxkf/>
       </div>
       <div class="fixed top-[330px] right-[3px] z-[999999999999]">
        <Lxdh/>
       </div>
       <div class="fixed bottom-[100px] right-[3px] z-[999999999999]">
        <Roll/>
       </div>
       <!-- 底部公司介绍 -->
        <Footer />
  </div>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/FooterView.vue';
import Lxkf from './components/lxkf.vue';
import Lxdh from './components/mobile.vue';
import Roll from './components/roll.vue';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Lxkf,
    Lxdh,
    Roll
  },
};

</script>
<style lang="scss" scoped>
@import url('./assets/font/YouSheBiaoTiHei/font.css');

</style>
