<template>
    <div>
        <!-- <div class="w-[60px] h-[60px] rounded-full bg-[#fff] pl-[12px] pt-[15px]" @click="showMobile">
            <img src="../assets/home/dianhua.png" alt="" class="w-[30px] h-[30px]">
        </div> -->
        <el-popover
            placement="left"
            trigger="click"
        >
            <div>
              <span class="mr-[10px]">189 5515 1876</span>
              <span class="text-[#5c6fdc] cursor-pointer" @click="copyNumber">复制</span>
            </div>
            <el-button slot="reference" class="w-[60px] h-[60px] box-show">
                <img src="../assets/home/dianhua.png" alt="" class="w-[100%] h-[100%]">
            </el-button>
        </el-popover>
    </div>
</template>
<script>
export default {
    name: 'Lxdh',
    data() {
        return {
            isShow : false
        }
    },
    methods: {
        async copyNumber() {
            const number = '189 5515 1876';
            try {
            await navigator.clipboard.writeText(number);
            this.$message.success('号码已复制到剪贴板');
            } catch (err) {
            this.$message.error('复制失败');
            }
        }
  }
}
</script>
<style lang="scss" scoped>
.box-show{box-shadow: 0px 0px 23px 0px #A7CAFF;}
.el-button {
  background-color: #fff;
  border-radius: 100%;
  padding: 0 !important;
}
// .texta{
//     cursor: pointer;
//   }
</style>