<template>
  <header class="text-white p-4">
    <nav>
      <ul class="flex items-center">
        <li class="ml-[10%] mr-[200px]">
          <div class="logo">
            <div class="w-[50px] h-[50px]">
              <img src="../assets/logo/yulianshuju.png" class="w-[50px] h-[50px]" alt="">
            </div>
            <p class="name">安徽羽链数据有限公司</p>
          </div>
        </li>
        <li class="mr-[50px] w-[130px]">
          <router-link to="/"  class="nav-link" exact>首页</router-link>
        </li>
        <li class="mr-[50px] w-[130px]">
          <router-link to="/profession"  class="nav-link" exact>业务领域</router-link>
        </li>
        <li class="mr-[50px] w-[130px]">
          <router-link to="/cooperation"  class="nav-link" exact>合作案例</router-link>
        </li>
        <li class="mr-[50px] w-[130px]">
          <router-link to="/about"  class="nav-link" exact>关于我们</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>
<style lang="scss" scoped>
.logo{
    width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name{
      font-size: 23px;
      color: #fff;
      font-family: '优设标题';
    }
  }
.nav-link {
  padding-bottom: 4px; /* 为横线留出空间 */
  position: relative;
  transition: all 0.3s ease; /* 添加过渡效果 */
}

.nav-link:hover {
  opacity: 0.8; /* 悬停时透明度变化 */
}

/* 选中时的样式 */
.nav-link.router-link-active {
  color: white; /* 选中时文字颜色保持白色 */
  font-size: 17px;
}

/* 底部横线 */
.nav-link.router-link-active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: white; /* 横线颜色为白色 */
  transform: scaleX(1); /* 横线显示 */
  transition: transform 0.3s ease; /* 横线动画 */
}

/* 未选中时的横线隐藏 */
.nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: white; /* 横线颜色为白色 */
  transform: scaleX(0); /* 初始状态隐藏 */
  transition: transform 0.3s ease;
}
</style>