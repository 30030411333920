import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/about.vue')
  },
  {
    path: '/cooperation',
    name: 'about',
    component: () => import('../views/cooperation/cooperation.vue')
  },
  {
    path: '/profession',
    name: 'about',
    component: () => import('../views/profession/profession.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
